import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

import store from '../store'

Vue.use(VueRouter)

const routes = [    
    // home page links
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home/Index')
    },
    
    // login page links
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Home/Login')
    },
    
    // forgot_password page links
    {
        path: '/forgot_password',
        name: 'forgot_password',
        component: () => import('../views/Home/ForgotPassword')
    },
    
    // sign up page links
    {
        path: '/signup',
        name: 'signup',
        component: () => import('../views/Home/SignUp')
        // component: () => import('../views/Home/SignUp')
    },
    
    //sign up  page two links
    {
        path: '/signup/two',
        name: 'signup_two',
        component: () => import('../views/Home/SignUpStepTwo'), 

    },
    
    //sign up page three links
    {
        path: '/signup/three',
        name: 'signup_three',
        component: () => import('../views/Home/SignUpStepThree'),
    },
    
    //sign up page three links
    {
        path: '/signup/otp',
        name: 'signup_otp',
        component: () => import('../views/Home/SignUpOTP'),
    },
    
    //sign up page four links
    {
        path: '/signup/four',
        name: 'signup_four',
        component: () => import('../views/Home/SignUpStepFour'),
        meta: {
            requireLogin: true
        }

    },
    
    //sign up create campaign page links
    {
        path: '/signup/create/campaign',
        name: 'signup_create_campaign',
        component: () => import('../views/Home/NewCampaign'),
        meta: {
            requireLogin: true
        }
    },
    
    //about us page links
    {
        path: '/about_us',
        name: 'about_us',
        component: () => import('../views/Home/AboutUs')
    },
    
    // product services
    {
        path: '/product_service',
        name: 'product_service',
        component: () => import('../views/Home/ProductService')
    },
    
    // our team
    {
        path: '/our_team',
        name: 'our_team',
        component: () => import('../views/Home/OurTeam')
    },
    
    // become a partner
    {
        path: '/become_partner',
        name: 'become_partner',
        component: () => import('../views/Home/BecomePartner'),
        meta: {
            requireLogin: true
        }
    },
    
    // contact us
    {
        path: '/contact_us',
        name: 'contact_us',
        component: () => import('../views/Home/ContactUs')
    },
    
    // Dashboard
    {
        path: '/dashboard/info',
        name: 'dashboard',
        component: () => import('../views/Dashboard/Layout'),
        children: [
            {
                path: ':types',
                name: 'dashboard.:types',
                component: () => import('../views/Dashboard/Info')
            },      
        ],
        meta: {
            requireLogin: true
        }
    },
    
    // user profile
    {
        path: '/user_profile',
        name: 'user_profile',
        component: () => import('../views/UserProfile/Layout'),
        children: [
            {
                path: 'account',
                name: 'user_profile.account',
                component: () => import('../views/UserProfile/Account')
            },      
            {
                path: 'security',
                name: 'user_profile.security',
                component: () => import('../views/UserProfile/Security')
            },
            {
                path: 'info',
                name: 'user_profile.info',
                component: () => import('../views/UserProfile/Info')
            }
        ],
        meta: {
            requireLogin: true
        }
    },
    
    // terms and conditions links
    {
        path: '/terms_conditions',
        name: 'terms_conditions',
        component: () => import('../views/Home/TermsConditions')
    },
    
    // faqs links 
    {
        path: '/faqs',
        name: 'faqs',
        component: () => import('../views/FAQs/Layout'),
        children: [
            {
                path: 'types/:types',
                name: 'faqs.:types',
                component: () => import('../views/FAQs/All')
            },
            {
                path: 'detail/:key',
                name: 'faqs.QA:key',
                component: () => import('../views/FAQs/QA')
            },
        ]
    },
    
    // savings home page links
    {
        path: '/savings',
        name: 'savings.home',
        component: () => import('../views/Savings/Home'),
    },
    
    // savings campaign pages links
    {
        path: '/savings/campaigns',
        name: 'campaigns',
        component: () => import('../views/Savings/Campaigns/Layout'),
        children: [
            {
                path: ':category',
                name: 'campaigns.:category',
                component: () => import('../views/Savings/Campaigns/All')
            },      
            {
                path: 'create/new_campaign',
                name: 'campaigns.new_campaign',
                component: () => import('../views/Savings/Campaigns/NewCampaign')
            },
            {
                path: 'detail/:campaign_id',
                name: 'campaigns.:campaign_id',
                component: () => import('../views/Savings/Campaigns/Campaign')
            },
            {
                path: 'save/:campaign_id',
                name: 'campaigns.save:campaign_id',
                component: () => import('../views/Savings/Campaigns/SaveNow')
            }
        ],
        meta: {
            requireLogin: true
        }

    },
    
    // savings loans pages links
    {
        path: '/savings/loans',
        name: 'loans',
        component: () => import('../views/Savings/Loans/Layout'),
        children: [
            {
                path: ':category',
                name: 'loans.:category',
                component: () => import('../views/Savings/Loans/All')
            },      
            {
                path: 'create/new_loan',
                name: 'loans.new_loan',
                component: () => import('../views/Savings/Loans/NewLoan')
            },
            {
                path: 'detail/:loan_id',
                name: 'loans.:loan_id',
                component: () => import('../views/Savings/Loans/Loan')
            },
            {
                path: 'save/:loan_id',
                name: 'loans.save:loan_id',
                component: () => import('../views/Savings/Loans/SaveNow')
            }
        ],
        meta: {
            requireLogin: true
        }

    },
    
    // savings transactions pages links
    {
        path: '/savings/transactions',
        name: 'savings_transactions',
        component: () => import('../views/Savings/Transactions/Layout'),
        children: [
            {
                path: ':category',
                name: 'savings_transactions.:category',
                component: () => import('../views/Savings/Transactions/All')
            },
        ],
        meta: {
            requireLogin: true
        }
    },
    
    // savings withdrawals pages links
    {
        path: '/Savings/withdrawals',
        name: 'savings_withdrawals',
        component: () => import('../views/Savings/Withdrawals/Layout'),
        children: [
            {
                path: 'request',
                name: 'savings_withdrawals.request',
                component: () => import('../views/Savings/Withdrawals/Request')
            },
            {
                path: 'fees',
                name: 'savings_withdrawals.fees',
                component: () => import('../views/Savings/Withdrawals/Fees')
            },
            {
                path: 'info',
                name: 'savings_withdrawals.info',
                component: () => import('../views/Savings/Withdrawals/Info')
            },
            {
                path: 'history',
                name: 'savings_withdrawals.history',
                component: () => import('../views/Savings/Withdrawals/RequestHistory')
            }
        ],
        meta: {
            requireLogin: true
        }
    },
    
    // savings contact us page links
    {
        path: '/savings/contact_us',
        name: 'savings.contact_us',
        component: () => import('../views/Savings/Contact_us/Layout'),
        children: [
            {
                path: 'all',
                name: 'savings.contact_us.all',
                component: () => import('../views/Savings/Contact_us/All')
            },
            {
                path: 'new_ticket',
                name: 'savings.contact_us.new_ticket',
                component: () => import('../views/Savings/Contact_us/NewTicket')
            },
            {
                path: 'detail/:ticket_id',
                name: 'savings.contact_us.:ticket_id',
                component: () => import('../views/Savings/Contact_us/TicketChat')
            },
        ],
        meta: {
            requireLogin: true
        }
    },
        
    // savings legal binding page links
    {
        path: '/savings/legal_binding',
        name: 'savings.legal_binding',
        component: () => import('../views/Savings/LegalBinding/Layout'),
        children: [
            {
                path: 'all',
                name: 'savings.legal_binding.all',
                component: () => import('../views/Savings/LegalBinding/All')
            },
            {
                path: 'detail/:key',
                name: 'savings.legal_binding.:key',
                component: () => import('../views/Savings/LegalBinding/TermDetail')
            },
        ],
        meta: {
            requireLogin: true
        }

    },
    
    // market place home page links
    {
        path: '/market_place',
        name: 'market_place.home',
        component: () => import('../views/MarketPlace/Home'),
    },
    
    // market_place items pages links
    {
        path: '/market_place/items',
        name: 'items',
        component: () => import('../views/MarketPlace/Items/Layout'),
        children: [
            {
                path: 'types/:type',
                name: 'items.:type',
                component: () => import('../views/MarketPlace/Items/All')
            },
            {
                path: ':key',
                name: 'items.:key',
                component: () => import('../views/MarketPlace/Items/Detail')
            },
        ],
    },
    
    // market_place my shopping bag pages links
    {
        path: '/market_place/shopping_bag/items',
        name: 'my_chart',
        component: () => import('../views/MarketPlace/MyChart/Layout'),
        children: [
            {
                path: 'my_items',
                name: 'my_chart.my_items',
                component: () => import('../views/MarketPlace/MyChart/MyItems')
            },
            {
                path: 'pay_now/:requested_order_id',
                name: 'my_chart.pay_now.:requested_order_id',
                component: () => import('../views/MarketPlace/MyChart/PayNow'),
                meta: {
                    requireLogin: true
                }
            },
            {
                path: 'transactions/make_payment/:requested_order_id',
                name: 'my_chart.make_payment.:requested_order_id',
                component: () => import('../views/MarketPlace/MyChart/MakePayment'),
                meta: {
                    requireLogin: true
                }
            },
            {
                path: 'transactions',
                name: 'my_chart.transactions',
                component: () => import('../views/MarketPlace/MyChart/Transactions'),
                meta: {
                    requireLogin: true
                }
            },
            {
                path: 'transactions/:requested_order_id',
                name: 'my_chart.transactions.:requested_order_id',
                component: () => import('../views/MarketPlace/MyChart/TransactionDetail'),
                meta: {
                    requireLogin: true
                }
            },
            {
                path: 'billing_info',
                name: 'my_chart.billing_info',
                component: () => import('../views/MarketPlace/MyChart/BillingReceipt'),
                meta: {
                    requireLogin: true
                }
            },
        ],
    },
    
    // market_place my shopping history pages links,
    {
        path: '/market_place/history/items',
        name: 'shopping_history',
        component: () => import('../views/MarketPlace/History/Layout'),
        children: [
            {
                path: 'types/:type',
                name: 'shopping_history.my_items.:type',
                component: () => import('../views/MarketPlace/History/All')
            },
            {
                path: ':key',
                name: 'shopping_history.:key',
                component: () => import('../views/MarketPlace/History/ShoppingDetail')
            },
        ],
        meta: {
            requireLogin: true
        }

    },
    
    // market place contact us page links
    {
        path: '/market_place/contact_us',
        name: 'market_place.contact_us',
        component: () => import('../views/MarketPlace/Contact_us/Layout'),
        children: [
            {
                path: 'all',
                name: 'market_place.contact_us.all',
                component: () => import('../views/MarketPlace/Contact_us/All')
            },
            {
                path: 'new_ticket',
                name: 'market_place.contact_us.new_ticket',
                component: () => import('../views/MarketPlace/Contact_us/NewTicket')
            },
            {
                path: 'detail/:ticket_id',
                name: 'market_place.contact_us.:ticket_id',
                component: () => import('../views/MarketPlace/Contact_us/TicketChat')
            },
        ],
        meta: {
            requireLogin: true
        }
    },
        
    // market place legal binding page links
    {
        path: '/market_place/legal_binding',
        name: 'market_place.legal_binding',
        component: () => import('../views/MarketPlace/LegalBinding/Layout'),
        children: [
            {
                path: 'all',
                name: 'market_place.legal_binding.all',
                component: () => import('../views/MarketPlace/LegalBinding/All')
            },
            {
                path: 'detail/:key',
                name: 'market_place.legal_binding.:key',
                component: () => import('../views/MarketPlace/LegalBinding/TermDetail')
            },
        ],
        meta: {
            requireLogin: true
        }
    },
    
    // njangi home page links
    {
        path: '/njangi',
        name: 'njangi.home',
        component: () => import('../views/Njangi/Home'),
    },
    
    // njangi groups pages links
    {
        path: '/njangi/groups',
        name: 'njangi_groups',
        component: () => import('../views/Njangi/Groups/Layout'),
        children: [
            {
                path: 'types/:category',
                name: 'njangi_groups.:category',
                component: () => import('../views/Njangi/Groups/All')
            },
            {
                path: 'detail/:key',
                name: 'njangi_groups.:key',
                component: () => import('../views/Njangi/Groups/Detail')
            },
            {
                path: 'terminate/:key',
                name: 'njangi_groups.terminate:key',
                component: () => import('../views/Njangi/Groups/TerminateNow')
            },
            {
                path: 'new_group',
                name: 'njangi_groups.new_group',
                component: () => import('../views/Njangi/Groups/NewGroup')
            },
            {
                path: 'new_group/one',
                name: 'njangi_groups.new_group_one',
                component: () => import('../views/Njangi/Groups/NewGroupStepOne')
            },
            {
                path: 'new_group/two/:application_id',
                name: 'njangi_groups.new_group_two.:application_id',
                component: () => import('../views/Njangi/Groups/NewGroupStepTwo')
            },
            {
                path: 'new_group/three/',
                name: 'njangi_groups.new_group_three',
                component: () => import('../views/Njangi/Groups/NewGroupStepThree')
            },
            {
                path: 'contribute/:group_id',
                name: 'njangi_groups.contribute:group_id',
                component: () => import('../views/Njangi/Groups/SaveNow')
            },
            {
                path: 'configure/detail/:new_group_id',
                name: 'njangi_groups.configure.:new_group_id',
                component: () => import('../views/Njangi/Groups/DetailConfiguration')
            },
            {
                path: 'complaints/:new_group_id',
                name: 'njangi_groups.complaints.:new_group_id',
                component: () => import('../views/Njangi/Groups/Complaints')
            },
            {
                path: 'cancel/group/:new_group_id',
                name: 'njangi_groups.cancel.:new_group_id',
                component: () => import('../views/Njangi/Groups/CancelNjangi')
            },
            {
                path: 'membership/:new_group_id',
                name: 'njangi_groups.membership.:new_group_id',
                component: () => import('../views/Njangi/Groups/MembershipSetting')
            },
        ],
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/njangi/groups/join_us',
        name: 'njangi_groups_join_us',
        component: () => import('../views/Njangi/Groups/Layout'),
        children: [
            {
                path: 'invitation/:new_group_id',
                name: 'njangi_groups_join_us.invitation.:new_group_id',
                component: () => import('../views/Njangi/Groups/acceptInvitation.vue')
            },
        ]
    },

    // njangi withdrawals pages links
    {
        path: '/njangi/withdrawals',
        name: 'njangi_withdrawals',
        component: () => import('../views/Njangi/Withdrawals/Layout'),
        children: [
            {
                path: 'request',
                name: 'njangi_withdrawals.request',
                component: () => import('../views/Njangi/Withdrawals/Request')
            },
            {
                path: 'fees',
                name: 'njangi_withdrawals.fees',
                component: () => import('../views/Njangi/Withdrawals/Fees')
            },
            {
                path: 'info',
                name: 'njangi_withdrawals.info',
                component: () => import('../views/Njangi/Withdrawals/Info')
            },
            {
                path: 'history',
                name: 'njangi_withdrawals.history',
                component: () => import('../views/Njangi/Withdrawals/RequestHistory')
            }
        ],
        meta: {
            requireLogin: true
        }
    },
    
    // njangi contact us page links
    {
        path: '/njangi/contact_us',
        name: 'njangi.contact_us',
        component: () => import('../views/Njangi/Contact_us/Layout'),
        children: [
            {
                path: 'all',
                name: 'njangi.contact_us.all',
                component: () => import('../views/Njangi/Contact_us/All')
            },
            {
                path: 'new_ticket',
                name: 'njangi.contact_us.new_ticket',
                component: () => import('../views/Njangi/Contact_us/NewTicket')
            },
            {
                path: 'detail/:ticket_id',
                name: 'njangi.contact_us.:ticket_id',
                component: () => import('../views/Njangi/Contact_us/TicketChat')
            },
        ],
        meta: {
            requireLogin: true
        }
    },
        
    // njangi legal binding page links
    {
        path: '/njangi/legal_binding',
        name: 'njangi.legal_binding',
        component: () => import('../views/Njangi/LegalBinding/Layout'),
        children: [
            {
                path: 'all',
                name: 'njangi.legal_binding.all',
                component: () => import('../views/Njangi/LegalBinding/All')
            },
            {
                path: 'detail/:key',
                name: 'njangi.legal_binding.:key',
                component: () => import('../views/Njangi/LegalBinding/TermDetail')
            },
        ],
        meta: {
            requireLogin: true
        }
    },
    
    // funding home page links
    {
        path: '/funding',
        name: 'funding.home',
        component: () => import('../views/Fundraising/Home'),
    },
    
    // funding projects pages links
    {
        path: '/funding/projects',
        name: 'funding_projects',
        component: () => import('../views/Fundraising/Projects/Layout'),
        children: [
            {
                path: 'types/:type',
                name: 'funding_projects.:type',
                component: () => import('../views/Fundraising/Projects/All')
            },
            {
                path: ':key',
                name: 'funding_projects.:key',
                component: () => import('../views/Fundraising/Projects/Detail')
            },
            {
                path: 'terminate/:key',
                name: 'funding_projects.terminate:key',
                component: () => import('../views/Fundraising/Projects/TerminateNow')
            },
            {
                path: 'new_project',
                name: 'funding_projects.new_project',
                component: () => import('../views/Fundraising/Projects/NewProject')
            },
        ],
        meta: {
            requireLogin: true
        }

    },
    
    // funding events pages links
    {
        path: '/funding/events',
        name: 'funding_events',
        component: () => import('../views/Fundraising/Events/Layout'),
        children: [
            {
                path: 'types/:type',
                name: 'funding_events.:type',
                component: () => import('../views/Fundraising/Events/All')
            },
            {
                path: ':key',
                name: 'funding_events.:key',
                component: () => import('../views/Fundraising/Events/Detail')
            },
            {
                path: 'terminate/:key',
                name: 'funding_events.terminate:key',
                component: () => import('../views/Fundraising/Events/TerminateNow')
            },
            {
                path: 'new_event',
                name: 'funding_events.new_event',
                component: () => import('../views/Fundraising/Events/NewEvent')
            },
        ],
        meta: {
            requireLogin: true
        }

    },
    
    // funding contact us page links
    {
        path: '/funding/contact_us',
        name: 'funding.contact_us',
        component: () => import('../views/Fundraising/Contact_us/Layout'),
        children: [
            {
                path: 'all',
                name: 'funding.contact_us.all',
                component: () => import('../views/Fundraising/Contact_us/All')
            },
            {
                path: 'new_ticket',
                name: 'funding.contact_us.new_ticket',
                component: () => import('../views/Fundraising/Contact_us/NewTicket')
            },
            {
                path: 'detail/:ticket_id',
                name: 'funding.contact_us.:ticket_id',
                component: () => import('../views/Fundraising/Contact_us/TicketChat')
            },
        ],
        meta: {
            requireLogin: true
        }

    },
        
    // funding legal binding page links
    {
        path: '/funding/legal_binding',
        name: 'funding.legal_binding',
        component: () => import('../views/Fundraising/LegalBinding/Layout'),
        children: [
            {
                path: 'all',
                name: 'funding.legal_binding.all',
                component: () => import('../views/Fundraising/LegalBinding/All')
            },
            {
                path: 'detail/:key',
                name: 'funding.legal_binding.:key',
                component: () => import('../views/Fundraising/LegalBinding/TermDetail')
            },
        ],
        meta: {
            requireLogin: true
        }

    },
    
    // monitor home page links
    {
        path: '/monitor',
        name: 'monitor.home',
        component: () => import('../views/Monitor/Home'),
    },
    
    // monitor dashboard pages links
    {
        path: '/monitor/dashboard',
        name: 'monitor_dashboard',
        component: () => import('../views/Monitor/MyDashboard/Layout'),
        children: [
            {
                path: ':type',
                name: 'monitor_dashboard.:type',
                component: () => import('../views/Monitor/MyDashboard/All')
            },
        ],
        meta: {
            requireLogin: true
        }

    },
    
    // monitor expenses pages links
    {
        path: '/monitor/expense',
        name: 'monitor_expense',
        component: () => import('../views/Monitor/MyExpense/Layout'),
        children: [
            {
                path: ':type',
                name: 'monitor_expenses.:type',
                component: () => import('../views/Monitor/MyExpense/All')
            },
        ],
        meta: {
            requireLogin: true
        }

    },
    
    // monitor income pages links
    {
        path: '/monitor/income',
        name: 'monitor_income',
        component: () => import('../views/Monitor/MyIncome/Layout'),
        children: [
            {
                path: ':type',
                name: 'monitor_income.:type',
                component: () => import('../views/Monitor/MyIncome/All')
            },
        ],
        meta: {
            requireLogin: true
        }

    },
    
    // monitor contact us page links
    {
        path: '/monitor/contact_us',
        name: 'monitor.contact_us',
        component: () => import('../views/Monitor/Contact_us/Layout'),
        children: [
            {
                path: 'all',
                name: 'monitor.contact_us.all',
                component: () => import('../views/Monitor/Contact_us/All')
            },
            {
                path: 'new_ticket',
                name: 'monitor.contact_us.new_ticket',
                component: () => import('../views/Monitor/Contact_us/NewTicket')
            },
            {
                path: 'detail/:ticket_id',
                name: 'monitor.contact_us.:ticket_id',
                component: () => import('../views/Monitor/Contact_us/TicketChat')
            },
        ],
        meta: {
            requireLogin: true
        }

    },
        
    // monitor legal binding page links
    {
        path: '/monitor/legal_binding',
        name: 'monitor.legal_binding',
        component: () => import('../views/Monitor/LegalBinding/Layout'),
        children: [
            {
                path: 'all',
                name: 'monitor.legal_binding.all',
                component: () => import('../views/Monitor/LegalBinding/All')
            },
            {
                path: 'detail/:key',
                name: 'monitor.legal_binding.:key',
                component: () => import('../views/Monitor/LegalBinding/TermDetail')
            },
        ],
        meta: {
            requireLogin: true
        }

    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    // to initialise authenticated
    if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated){
        store.commit('initialiseStore')
    }

    if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated){
        next({name: 'login', query: {to: to.path} });
        store.commit('setSnackBarMessage', "Login is required: Please log into your account or create on now.")
        store.commit('setSnackBarColor', "red darken-4")
        store.commit('activateSnackBar', true)
    }  
    else{
        const token = store.state.token
        if (token){
            axios.defaults.headers.common['Authorization'] = "Token " + token
        } else {
            axios.defaults.headers.common['Authorization'] = ""
        }

        if (to.path.includes("/funding/") || to.path.includes("/monitor/")){
            store.commit('activateDialog', true)
            store.commit('setDialogTitle', "Coming Soon 😊 ....")
            store.commit('setDialogMessage', "<div > <span style='float: left; font-size: 38px!important' >🛠️</span> <p style='text-align: justify;'>Our Fundraising and Financial Monitor services are still under construction for you.</p></div>")
    
            next({name: 'home', query: {to: to.path} });
        }

        next()
    }
})
export default router
